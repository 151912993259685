import styled from "styled-components";
import Breakpoints from "../config/Breakpoints";
import PointsFormatUtil from '../helpers/PointFormatUtil';
import ButtonAddToCart from "./ButtonAddToCart";
import StyledButton from "./Button";
import arrowRightIcon from "../assets/icons/linkArrowRightWhite.svg";

const ProductPrice = ({productData}) => {  
    const Container = styled.div`
      padding: 1.5rem;
      border-radius: .5rem;
      border: 1px solid var(--color-lightGray);
      display: flex;
      flex-direction: column;
      gap: 1rem;
      
      @media ${Breakpoints.md} {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        align-self: flex-start;
      }
  
  
      h1 {
        margin: 0;
        font-size: var(--font-size-xxl);
        line-height: 1em;
      }
  
      .points {
        padding: 1.5rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid var(--color-lightGray);
        gap: .5rem;
        justify-content: center;
  
        strong {
          font-size: var(--font-size-xxl);
          line-height: 1em;
        }
  
        span {
          text-transform: uppercase;
          font-size: .625rem;
          letter-spacing: .08em;
          font-weight: var(--font-weight-bold);
        }
      }
    `
  
    return (
      <Container>
        <h1>{productData.name}</h1>
        {productData.value !== null && (
          <>
            <div className="points">
              <strong>{PointsFormatUtil(productData.value)}</strong>
              <span>pontos</span>
            </div>
            <ButtonAddToCart productData={productData}>
                Adicionar ao carrinho
                <img src={arrowRightIcon} alt="" />
            </ButtonAddToCart>
          </>
        )}
        {productData.value === null && (
          <StyledButton disabled={true} style={{width: "100%", borderRadius: ".5rem"}}>
            Produto indisponível
          </StyledButton>
        )}
      </Container>
    )
  } 

  export default ProductPrice;