
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask'
import styled from "styled-components";
import PrepareFetch from '../helpers/PrepareFetch';

import {PinDrop, LocalShipping} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const Container = styled.div`
  padding: 1.5rem;
  border-radius: .5rem;
  border: 1px solid var(--color-lightGray);
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .small {
    font-size: .875rem
  }
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: .75rem;
  height: 1.75rem;

  input {
    border: none;
    font-size: 1rem;
    outline: none;
    flex: 1;
  }
`

const Green = styled.span`
  color: var(--color-brand);
`

const ProductDeliveryDate = ({productData}) => {
  const endpoint = `/proxy/services/apexrest/api/v1/entrega-rapida/days-to-deliver`;
  const zipCodeStoreKey = 'intelbras-pontua::zipcode';

  const [isLoading, setIsLoading] = useState(false);
  const [daysToDeliver, setDaysToDeliver] = useState();
  const [error, setError] = useState('');
  const [zipCode, setZipCode] = useState('');

  useEffect(() => {
    const zipCode = localStorage.getItem(zipCodeStoreKey);
    if (zipCode) {
      setZipCode(zipCode);
      loadDeliveryDate(zipCode);
    }
  }, []);

  const changeZipCode = (e) => {
    setZipCode(e.target.value);
    const zipcode = e.target.value.replace(/_|-/g,"");

    if (zipcode.length === 8) {
      loadDeliveryDate(zipcode);
    } else {
      if (daysToDeliver) {
        setDaysToDeliver(null);
      }
    }
  }

  const loadDeliveryDate = async (zipCode) => {    
    setIsLoading(true);

    const urlWithParams = `${endpoint}?sku=${productData.fastTrackingSku}&zipCode=${zipCode}`;

    const fetchParams = PrepareFetch(urlWithParams, "GET", {
      "cache-by": "user"
    });
    
    try {
      const httpResponse = await fetch(fetchParams.url, fetchParams.options);

      const {numDays, error} = await httpResponse.json();
      
      if (error) {
        setError(error);
        throw new Error(error);
      }

      setDaysToDeliver(numDays);
      localStorage.setItem(zipCodeStoreKey, zipCode)
    } catch(e) {
      console.log('Error on load days to deliver', e);
    }

    setIsLoading(false);
  }

  return (
    <Container>
      <InputContainer>
        <PinDrop />
        <InputMask
          id='zipcode-input'
          mask='99999-999'
          placeholder='Calcular o frete'
          maskChar="_"
          onChange={changeZipCode}
          value={zipCode}
        />
        {isLoading && <CircularProgress color='success' size={28} />}
      </InputContainer>
      {(daysToDeliver && !error) && (
        <Row>
          <LocalShipping />
          <div>
            <div><b>Seu produto chega em até <Green>{daysToDeliver} dias</Green></b></div>
            <div className='small'>Após a confirmação do pedido</div>
          </div>
        </Row>
      )}
      {error && (
        <Alert sx={{mt: '1rem'}} severity="error">{error}</Alert>
      )}
      
    </Container>
  );
};

export default ProductDeliveryDate;
