const ExtractProductImages = (imageObject) => {
    let rtn = [];
    
    for(const [key, value] of Object.entries(imageObject)) {
        rtn.push({
            position: key,
            url: value.ContentDocument.LatestPublishedVersion.ContentUrl
        });
    }

    if(rtn.length === 0) {
        return null;
    }

    return rtn;
}

const ProductFacades = (json) => {
    return json.map(item => {
        return {
            id: item.reward.id,
            images: ExtractProductImages(item.images),
            name: item.reward.name,
            description: item.reward.description,
            value: item.reward.value || null,
            categoryName: item.reward.categoryName || null,
            categoryId: item.reward.categoryId || null,
            fastTracking: item.reward.fastTracking ?? false,
            fastTrackingSku: item.reward.fastTrackingSku ?? null
        }
    })
}

export default ProductFacades;